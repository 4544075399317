import { flowRight } from 'lodash';
import { addDays, addWeeks, addMonths, addYears, isAfter } from 'date-fns';
import React from 'react';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import classNames from 'classnames';
import {
  InjectedTranslateProps,
  translate,
  InjectedExperimentsProps,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { getStatusText, StatusBadge } from './StatusBadge';
import {
  SubscriptionFrequency,
  SubscriptionStatus,
  SubscriptionTrialPeriod,
  SubscriptionType,
  V1Subscription,
} from '@wix/ambassador-subscriptions-api/types';
import { Balance, BenefitType } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Subscription } from '@wix/ambassador-wix-ecommerce-subscriptions/types';
import { connect } from '../../../common/components/runtime-context';
import { ArrowUpIcon } from './ArrowUpIcon';
import { ArrowDownIcon } from './ArrowDownIcon';
import { TextSubscriptionName } from './TextSubscriptionName';
import {
  getDetails,
  getRegionalSettings,
  isDetailsLoading as isDetailsLoadingSelector,
  isDetailsOpen as isDetailsOpenSelector,
} from '../selectors';
import { TextSubscriptionInfo } from './TextSubscriptionInfo';
import { st, classes } from './SubscriptionsListItem.st.css';
import { PAID_PLANS_APP_DEF_ID, STORES_APP_DEF_ID } from '../constants';
import settingsParams from '../settingsParams';

type SubscriptionsListItemProps = InjectedTranslateProps &
  InjectedExperimentsProps & {
    subscription: V1Subscription;
    openDetails: Function;
    closeDetails: Function;
    isDetailsOpen: boolean;
    isDetailsLoading: boolean;
    details: any;
    language: string;
    navigateToPricingPlans: () => void;
    navigateToStores: () => void;
    cancelSubscription: () => void;
  };

const buildTrialEndDate = (date, trial: SubscriptionTrialPeriod) => {
  const fnMap = {
    [SubscriptionFrequency.DAY]: addDays,
    [SubscriptionFrequency.WEEK]: addWeeks,
    [SubscriptionFrequency.MONTH]: addMonths,
    [SubscriptionFrequency.YEAR]: addYears,
    [SubscriptionFrequency.UNDEFINED]: (_date) => _date,
  };
  return fnMap[trial.unit](date, trial.period);
};

const SubscriptionsListItem = ({
  experiments,
  subscription,
  isDetailsOpen,
  isDetailsLoading,
  openDetails,
  closeDetails,
  details,
  language,
  navigateToPricingPlans,
  navigateToStores,
  cancelSubscription,
  t,
}: SubscriptionsListItemProps) => {
  const settings = useSettings();

  const formatDate = (date) => {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return date.toLocaleDateString(language, {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const formatMoney = (amount: Number, currency: string) => {
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString(language, { style: 'currency', currency });
  };

  const isCancelSubscriptionsEnabled = experiments.enabled('specs.subscriptionsTpa.CancelSubscriptions');
  const isPPSubscription = subscription.wixAppId === PAID_PLANS_APP_DEF_ID;
  const isStoresSubscription = subscription.wixAppId === STORES_APP_DEF_ID;

  const navigateToVertical = () => {
    if (isPPSubscription) {
      return navigateToPricingPlans();
    }

    if (isStoresSubscription) {
      return navigateToStores();
    }
  };

  let trialEndDate,
    inTrial = false;

  const hasEndDate = Boolean(subscription.validUntil);
  const hasTrial = Boolean(subscription.billingProfile?.trial);

  if (hasTrial) {
    trialEndDate = buildTrialEndDate(subscription.validFrom, subscription.billingProfile.trial);
    inTrial = subscription.subscriptionStatus === SubscriptionStatus.ACTIVE && isAfter(trialEndDate, new Date());
  }

  const getFieldA = () => {
    const lines = [];
    if (subscription.description && subscription.wixAppId === STORES_APP_DEF_ID) {
      lines.push(subscription.description);
    }
    lines.push(subscription.name);
    return lines;
  };

  const getFieldB = () => {
    const lines = [];
    if (subscription.price) {
      if (subscription.recurring && subscription.billingProfile?.frequency) {
        const map = {
          [SubscriptionFrequency.DAY]: 'app.details.price-per-day',
          [SubscriptionFrequency.WEEK]: 'app.details.price-per-week',
          [SubscriptionFrequency.MONTH]: 'app.details.price-per-month',
          [SubscriptionFrequency.YEAR]: 'app.details.price-per-year',
          [SubscriptionFrequency.UNDEFINED]: 'app.details.price-undefined',
        };
        lines.push(
          t(map[subscription.billingProfile.frequency], {
            price: formatMoney(subscription.price.amount, subscription.price.currency),
          }),
        );
      } else {
        lines.push(formatMoney(subscription.price.amount, subscription.price.currency));
      }
    } else {
      lines.push(t('app.details.free-plan'));
    }
    if (subscription.subscriptionType === SubscriptionType.ONLINE) {
      if (details?.paymentSubscriptionInfo) {
        const {
          totalBillingCycles,
          totalSuccessfulPayments,
          lastSuccessfullyChargedDate,
          nextChargeAttemptDate,
          paymentMethod,
        } = details.paymentSubscriptionInfo;
        if (hasEndDate && totalBillingCycles) {
          lines.push(
            t('app.details.payments-completed-of-total', {
              totalBillingCycles,
              totalSuccessfulPayments: totalSuccessfulPayments || 0,
            }),
          );
        } else {
          lines.push(
            t('app.details.payments-completed', {
              totalSuccessfulPayments: totalSuccessfulPayments || 0,
            }),
          );
        }
        if (lastSuccessfullyChargedDate) {
          lines.push(
            t('app.details.last-payment', {
              date: formatDate(lastSuccessfullyChargedDate),
            }),
          );
        }
        if (nextChargeAttemptDate) {
          lines.push(
            t('app.details.next-payment', {
              date: formatDate(nextChargeAttemptDate),
            }),
          );
        }

        if (paymentMethod === 'creditCard') {
          lines.push(t('app.details.payment-method-credit-card'));
        }
        if (paymentMethod === 'payPal') {
          lines.push(t('app.details.payment-method-paypal'));
        }
      }
    }
    if (subscription.subscriptionType === SubscriptionType.OFFLINE) {
      lines.push(t('app.details.payment-method-offline'));
    }
    return lines;
  };

  const renderFieldC = ({ buyNewPlanText, cancelSubscriptionText }) => {
    const hasBuyNewPlanStatus =
      [SubscriptionStatus.EXPIRED, SubscriptionStatus.PENDING_CANCELLATION, SubscriptionStatus.CANCELED].indexOf(
        subscription.subscriptionStatus,
      ) > -1;

    const hasCancelPlanStatus =
      [SubscriptionStatus.ACTIVE, SubscriptionStatus.PENDING].indexOf(subscription.subscriptionStatus) > -1;

    let cta;
    if ((isPPSubscription || isStoresSubscription) && hasBuyNewPlanStatus) {
      cta = (
        <TextButton className={classes.cta} onClick={navigateToVertical}>
          {buyNewPlanText}
        </TextButton>
      );
    }
    if (isCancelSubscriptionsEnabled && hasCancelPlanStatus && subscription.policy?.cancellableByMember) {
      cta = (
        <TextButton className={classes.cta} onClick={cancelSubscription}>
          {cancelSubscriptionText}
        </TextButton>
      );
    }

    if (cta) {
      return <div className={classes.ctaContainer}>{cta}</div>;
    }
    return null;
  };

  const getFieldD = () => {
    switch (subscription.subscriptionStatus) {
      case SubscriptionStatus.PENDING:
        return t('app.title.starts', {
          date: formatDate(subscription.validFrom),
        });
      case SubscriptionStatus.ACTIVE:
        if (hasEndDate) {
          return t('app.title.expires', {
            date: formatDate(subscription.validUntil),
          });
        }
        return t('app.title.valid-until-canceled');
      case SubscriptionStatus.PENDING_CANCELLATION:
        return t('app.title.expires', {
          date: formatDate(subscription.validUntil),
        });
      case SubscriptionStatus.SUSPENDED:
        return null;
      case SubscriptionStatus.EXPIRED:
        return t('app.title.expired', {
          date: formatDate(subscription.validUntil),
        });
      case SubscriptionStatus.CANCELED:
        return t('app.title.canceled', {
          date: formatDate(subscription.validUntil),
        });
      default:
        return null;
    }
  };

  const getFieldE = () => {
    if (subscription.subscriptionStatus === SubscriptionStatus.PENDING) {
      if (hasEndDate) {
        return t('app.details.expires', {
          date: formatDate(subscription.validUntil),
        });
      }
      return t('app.details.valid-until-canceled');
    }
    return t('app.details.start-date', {
      date: formatDate(subscription.validFrom),
    });
  };

  const getFieldF = () => {
    if (inTrial) {
      return t('app.details.trial-ends', { date: formatDate(trialEndDate) });
    }
    return null;
  };

  const getFieldG = () => {
    const lines = [];
    if (details?.benefitBalanceItems) {
      const { benefit } = details.benefitBalanceItems[0] as Balance;
      if (benefit.benefitType === BenefitType.LIMITED) {
        lines.push(
          t('app.details.sessions-remaining-without-total', {
            remainingCredits: benefit.remainingCredits,
            totalCredits: benefit.totalCredits,
          }),
        );
      }
    }
    if (details?.storesSubscription) {
      const { lineItems, shippingInfo } = details.storesSubscription as Subscription;
      lines.push(t('app.details.quantity', { quantity: lineItems[0].quantity }));
      if (shippingInfo?.shipmentDetails?.address) {
        const address = shippingInfo.shipmentDetails.address;
        const addressLines = ['addressLine1', 'addressLine2', 'city', 'zipCode', 'country']
          .map((field) => address[field])
          .filter((line) => line);

        if (addressLines.length) {
          lines.push(t('app.details.shipping-to'));
          addressLines.forEach((line) => lines.push(line));
        }
      }
    }
    return lines;
  };

  const fieldA = getFieldA();
  const fieldB = getFieldB();
  const fieldD = getFieldD();
  const fieldE = getFieldE();
  const fieldF = getFieldF();
  const fieldG = getFieldG();

  return (
    <TPAComponentsConsumer>
      {({ mobile }) => (
        <div className={st(classes.root, { mobile })} data-hook="subscription-list-item">
          <div
            id={`subscription-title-${subscription.id}`}
            className={classes.titleContainer}
            data-hook="subscription-list-item-title"
            onClick={() => (isDetailsOpen ? closeDetails() : openDetails())}
          >
            <div className={classes.lft}>
              <div className={classNames(classes.title, classes.columnA)}>
                {fieldA.map((line, i) => (
                  <div key={i}>
                    <TextSubscriptionName>{line}</TextSubscriptionName>
                  </div>
                ))}
              </div>
              {fieldD && (
                <div className={classes.columnB}>
                  <TextSubscriptionName>{fieldD}</TextSubscriptionName>
                </div>
              )}
              {mobile && (
                <div className={classes.statusBadgeContainer}>
                  <StatusBadge subscription={subscription} />
                </div>
              )}
            </div>
            <div className={classes.rgt}>
              {!mobile && (
                <div className={classes.tooltipWrapper}>
                  <Tooltip content={getStatusText(subscription, t)} placement="top">
                    <div className={classes.statusBadgeContainer}>
                      <StatusBadge subscription={subscription} />
                    </div>
                  </Tooltip>
                </div>
              )}
              <div className={classes.iconContainer}>
                <button
                  style={{ fontSize: 0 }}
                  aria-expanded={isDetailsOpen ? 'true' : 'false'}
                  aria-label="more details"
                  aria-describedby={`subscription-title-${subscription.id}`}
                >
                  {isDetailsOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </button>
              </div>
            </div>
          </div>

          {isDetailsOpen && (
            <div className={classes.details} data-hook="subscription-list-item-details">
              <div className={classes.contentContainer}>
                {isDetailsLoading && !details && (
                  <div className={classes.loader}>
                    <Spinner />
                  </div>
                )}
                {details && (
                  <>
                    <div className={classes.columnA}>
                      {fieldB.map((line, i) => (
                        <div key={i}>
                          <TextSubscriptionInfo>{line}</TextSubscriptionInfo>
                        </div>
                      ))}
                    </div>
                    <div className={classes.columnB}>
                      <div>
                        <TextSubscriptionInfo>{fieldE}</TextSubscriptionInfo>
                      </div>
                      {fieldF && (
                        <div>
                          <TextSubscriptionInfo>{fieldF}</TextSubscriptionInfo>
                        </div>
                      )}
                      {fieldG.map((line, i) => (
                        <div key={i}>
                          <TextSubscriptionInfo>{line}</TextSubscriptionInfo>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              {details &&
                renderFieldC({
                  buyNewPlanText: settings.get(settingsParams.buyNewPlanText),
                  cancelSubscriptionText: settings.get(settingsParams.cancelSubscriptionText),
                })}
            </div>
          )}
        </div>
      )}
    </TPAComponentsConsumer>
  );
};

const mapRuntimeToProps = (
  state,
  { subscription },
  { openDetails, closeDetails, navigateToPricingPlans, navigateToStores, openCancelConfirmModal },
) => ({
  isDetailsOpen: isDetailsOpenSelector(state, subscription.id),
  isDetailsLoading: isDetailsLoadingSelector(state, subscription.id),
  openDetails: () => openDetails(subscription.id),
  closeDetails: () => closeDetails(subscription.id),
  details: getDetails(state, subscription.id),
  language: getRegionalSettings(state),
  navigateToPricingPlans,
  navigateToStores,
  cancelSubscription: () => openCancelConfirmModal(subscription.id),
});

export default flowRight(connect(mapRuntimeToProps), withExperiments, translate())(SubscriptionsListItem);
