import React from 'react';
import { InjectedTranslateProps, translate, TranslationFunction } from 'yoshi-flow-editor-runtime';
import { SubscriptionStatus, V1Subscription } from '@wix/ambassador-subscriptions-api/types';
import { addDays, isAfter } from 'date-fns';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { st, classes } from './StatusBadge.st.css';

type StatusBadgeProps = InjectedTranslateProps & {
  subscription: V1Subscription;
};

const activeStatuses = [SubscriptionStatus.ACTIVE, SubscriptionStatus.PENDING_CANCELLATION];

const statusTranslations = {
  [SubscriptionStatus.UNDEFINED]: 'app.subscription-status.undefined',
  [SubscriptionStatus.DRAFT]: 'app.subscription-status.draft',
  [SubscriptionStatus.PENDING]: 'app.subscription-status.pending',
  [SubscriptionStatus.ACTIVE]: 'app.subscription-status.active',
  [SubscriptionStatus.EXPIRED]: 'app.subscription-status.expired',
  [SubscriptionStatus.CANCELED]: 'app.subscription-status.canceled',
  [SubscriptionStatus.PENDING_CANCELLATION]: 'app.subscription-status.pending-cancellation',
  [SubscriptionStatus.SUSPENDED]: 'app.subscription-status.suspended',
};

const isInFreeTrial = (subscription: V1Subscription) => {
  if (subscription.subscriptionStatus === SubscriptionStatus.ACTIVE && subscription.billingProfile?.trial?.period) {
    const freeTrialEnd = addDays(new Date(subscription.validFrom), subscription.billingProfile.trial.period);
    const today = new Date();
    if (isAfter(freeTrialEnd, today)) {
      return true;
    }
  }

  return false;
};

export const getStatusText = (subscription: V1Subscription, t: TranslationFunction) => {
  const inFreeTrial = isInFreeTrial(subscription);
  return inFreeTrial ? t('app.subscription-status.free-trial') : t(statusTranslations[subscription.subscriptionStatus]);
};

export const StatusBadge = translate()(({ subscription, t }: StatusBadgeProps) => {
  const inFreeTrial = isInFreeTrial(subscription);
  const isActive = inFreeTrial || activeStatuses.indexOf(subscription.subscriptionStatus) > -1;

  return (
    <Badge
      className={st(classes.root, { active: isActive })}
      priority={BADGE_PRIORITY.default}
      data-hook="status-badge"
    >
      {getStatusText(subscription, t)}
    </Badge>
  );
});
