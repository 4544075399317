import React from 'react';

export const ArrowDownIcon = (props) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>09 Icons / Arrow / Down / Align / Center</title>
    <defs>
      <polygon
        id="path-1"
        points="11.9994992 9 6 14.2409396 6.65704031 15 11.9994992 10.3338926 17.3429597 15 18 14.2409396"
      ></polygon>
    </defs>
    <g id="09-Icons-/-Arrow-/-Down-/-Align-/-Center" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <mask id="mask-2" fill="white">
        <use href="#path-1" />
      </mask>
      <use
        id="🎨-Color"
        fill="#000000"
        transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
        href="#path-1"
      />
    </g>
  </svg>
);
