import React from 'react';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { Divider } from './Divider';
import { st, classes } from './EmptyState.st.css';
import { connect } from '../../../common/components/runtime-context';
import settingsParams from '../settingsParams';

interface Props {
  ppInstalled: boolean;
  navigateToPricingPlans: ({ emptyState: boolean }) => void;
}

const EmptyState = ({ ppInstalled, navigateToPricingPlans }: Props) => {
  const settings = useSettings();
  return (
    <TPAComponentsConsumer>
      {({ mobile }) => (
        <div className={st(classes.root, { mobile })} data-hook="empty-state">
          {!mobile && <Divider />}
          <div className={classes.content}>
            <p className={classes.noSubscriptionText}>{settings.get(settingsParams.noSubscriptionText)}</p>
            {ppInstalled && (
              <TextButton className={classes.cta} onClick={() => navigateToPricingPlans({ emptyState: true })}>
                {settings.get(settingsParams.pricingPlansLinkText)}
              </TextButton>
            )}
          </div>
          {!mobile && <Divider />}
        </div>
      )}
    </TPAComponentsConsumer>
  );
};

const mapRuntimeToProps = (_, __, { navigateToPricingPlans }) => ({
  navigateToPricingPlans,
});

export default connect(mapRuntimeToProps)(EmptyState);
