import React from 'react';
import { Dialog } from 'wix-ui-tpa/Dialog';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import { V1Subscription } from '@wix/ambassador-subscriptions-api/types';
import { connect } from '../../../common/components/runtime-context';
import { getCancelConfirmModalSubscription, isCancelConfirmModalOpen } from '../selectors';
import { flowRight } from 'lodash';
import { InjectedTranslateProps, translate } from 'yoshi-flow-editor-runtime';
import { classes } from './CancelConfirmModal.st.css';

type CancelConfirmModal = InjectedTranslateProps & {
  isOpen: boolean;
  subscription: V1Subscription;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

export const CancelConfirmModal = ({ isOpen, subscription, onClose, onCancel, onConfirm, t }: CancelConfirmModal) => {
  return (
    <Dialog
      data-hook={'cancel-confirm-modal'}
      isOpen={isOpen}
      onClose={onClose}
      closeButtonAriaLabel={t('app.cancel-confirm-modal.close')}
    >
      {isOpen ? (
        <>
          <div className={classes.title}>
            <Text className={classes.text} typography={TYPOGRAPHY.largeTitle}>
              {t('app.cancel-confirm-modal.title', { subscriptionName: subscription.name })}
            </Text>
          </div>
          <div data-hook={'cancel-confirm-modal-description'} className={classes.description}>
            <Text className={classes.text} typography={TYPOGRAPHY.runningText}>
              {t(
                subscription.recurring
                  ? 'app.cancel-confirm-modal.description-recurring'
                  : 'app.cancel-confirm-modal.description',
              )}
            </Text>
          </div>
          <div className={classes.actionContainer}>
            <Button className={classes.buttonPrimary} upgrade priority={PRIORITY.basicSecondary} onClick={onCancel}>
              {t('app.cancel-confirm-modal.cancel')}
            </Button>
            <Button className={classes.buttonSecondary} upgrade priority={PRIORITY.basic} onClick={onConfirm}>
              {t('app.cancel-confirm-modal.confirm')}
            </Button>
          </div>
        </>
      ) : null}
    </Dialog>
  );
};

const mapRuntimeToProps = (state, ownProps, { closeCancelConfirmModal, confirmCancel }) => ({
  isOpen: isCancelConfirmModalOpen(state),
  subscription: getCancelConfirmModalSubscription(state),
  onClose: () => closeCancelConfirmModal(),
  onCancel: () => closeCancelConfirmModal(),
  onConfirm: () => confirmCancel(),
});

export default flowRight(connect(mapRuntimeToProps), translate())(CancelConfirmModal);
