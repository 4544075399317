import React from 'react';
import { RootState } from '../../../components/MySubscriptions/state';

interface IContextParams {
  state: RootState;
  actions: any;
  host: any;
}

export default React.createContext<IContextParams>({
  state: undefined,
  actions: undefined,
  host: undefined,
});
