import React from 'react';
import { translate, InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { TPAComponentsProvider, TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';
import { st, classes } from './Widget.st.css';
import EmptyState from './EmptyState';
import SubscriptionsList from './SubscriptionsList';
import RuntimeContext from '../../../common/components/runtime-context';
import { TabState } from '../../../types/settings';
import { RootState } from '../state';
import CancelConfirmModal from './CancelConfirmModal';
import settingsParams from '../settingsParams';

interface WidgetWrapperProps {
  state: RootState;
  methods: Partial<any>;
  host: Partial<any>;
  tabState: TabState;
  ppInstalled: boolean;
  storesInstalled: boolean;
}

type WidgetProps = InjectedTranslateProps & WidgetWrapperProps;

export default class extends React.Component<WidgetWrapperProps> {
  render() {
    const { state, methods, host, tabState, ppInstalled, storesInstalled } = this.props;
    return (
      <TPAComponentsProvider value={{ mobile: this.props.host.formFactor === 'Mobile' }}>
        <RuntimeContext.Provider value={{ state, actions: methods, host }}>
          <Widget
            state={state}
            methods={methods}
            host={host}
            tabState={tabState}
            ppInstalled={ppInstalled}
            storesInstalled={storesInstalled}
          />
        </RuntimeContext.Provider>
      </TPAComponentsProvider>
    );
  }
}

export const Widget = translate()(({ t, tabState, ppInstalled, storesInstalled, methods, ...rest }: WidgetProps) => {
  const settings = useSettings();
  return (
    <TPAComponentsConsumer>
      {({ mobile }) => (
        <RuntimeContext.Consumer>
          {({ state }) => (
            <div
              role={'region'}
              aria-labelledby={'my-subscriptions-title'}
              className={st(classes.root, { mobile })}
              data-hook="MySubscriptions-wrapper"
            >
              {!mobile && (
                <div className={classes.header}>
                  <h1 id="my-subscriptions-title" data-hook="app-page-title">
                    {settings.get(settingsParams.pageTitleText)}
                  </h1>
                  <p data-hook="app-page-description">{settings.get(settingsParams.pageDescriptionText)}</p>
                </div>
              )}
              <div className={classes.content}>
                {!state.subscriptions || state.subscriptions.entities.length === 0 || tabState === TabState.Empty ? (
                  <EmptyState ppInstalled={ppInstalled} />
                ) : (
                  <>
                    <SubscriptionsList subscriptions={state.subscriptions.entities} />
                    <CancelConfirmModal />
                  </>
                )}
              </div>
            </div>
          )}
        </RuntimeContext.Consumer>
      )}
    </TPAComponentsConsumer>
  );
});
