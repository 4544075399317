
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/373821cf82c7c4b7/subscriptions/subscriptions-tpa/src/components/MySubscriptions/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/agent00/work/373821cf82c7c4b7/subscriptions/subscriptions-tpa/src/components/MySubscriptions/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://5c70d1623f10432da8b4b6562ee0599b@sentry.wixpress.com/463',
      id: '5c70d1623f10432da8b4b6562ee0599b',
      projectName: 'subscriptions-tpa',
      teamName: 'pricing-plans',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'MySubscriptions'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  export default {
    component: UserComponent
  };
