import React from 'react';
import { TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';
import { V1Subscription } from '@wix/ambassador-subscriptions-api/types';
import { Divider } from './Divider';
import SubscriptionsListItem from './SubscriptionsListItem';
import { st, classes } from './SubscriptionsList.st.css';

interface SubscriptionsListProps {
  subscriptions: V1Subscription[];
}

const SubscriptionsList = ({ subscriptions }: SubscriptionsListProps) => (
  <TPAComponentsConsumer>
    {({ mobile }) => (
      <>
        {!mobile && <Divider />}
        <ul className={st(classes.root, { mobile })} data-hook="subscriptions-list">
          {subscriptions.map((subscription) => (
            <li key={subscription.id}>
              <SubscriptionsListItem subscription={subscription} />
              <Divider />
            </li>
          ))}
        </ul>
      </>
    )}
  </TPAComponentsConsumer>
);

export default SubscriptionsList;
